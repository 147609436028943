<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <!--营业执照信息-->
      <div class="formDiv">
        <ProductBaseInfo v-show="clickIndex == 1" ref="productBaseInfo"></ProductBaseInfo>
        <RegisteCerticate v-show="clickIndex == 2" ref="registeCerticate"></RegisteCerticate>
        <RecordCertificateP v-show="clickIndex == 3" ref="recordCertificateP"></RecordCertificateP>
        <DealerInfo v-show="clickIndex == 4" ref="dealerInfo"></DealerInfo>
        <ProductApproval v-show="clickIndex == 5" ref="approval"></ProductApproval>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20" v-if="status == 2 && clickIndex == 5" @click="pass(1)">保存</div>
        <div class="pageBtn finger btnSure fr marginLeft20" v-if="status == 3 || (status == 2 && clickIndex != 5)" @click="editSave">保存</div>
        <div class="longTextBtn finger btnSure fr marginLeft20" v-if="status == 2 && clickIndex == 5 && showPass" @click="pass(2)">一键通过</div>
        <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      </div>
    </div>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!-- 验证产品id -->
    <Modal v-model="chextShow" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <div class="flDiv fl">
          <div class="white_top">
            <p>提交</p>
            <img src="../../../assets/images/tipsPostImg.png" class="tipsImg fl" />
          </div>
          <div class="white">产品编号-子编号<br />{{ title }}重复，是否确认保存</div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="chextShow = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="confirmClick">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'productFrame',
  components: {
    ProductBaseInfo: () => import('./productBaseInfo'),
    RegisteCerticate: () => import('./registeCerticate'),
    RecordCertificateP: () => import('./recordCertificateP'),
    DealerInfo: () => import('./dealerInfo'),
    ProductApproval: () => import('./productApproval'),
    TipsComponent,
  },
  computed: {
    returnDisabled(list) {
      let that = this
      return function (list) {
        let arr = [...list, ...that.roleList]
        if (new Set(arr).size != arr.length) {
          return true
        } else {
          return false
        }
      }
    },
  },
  data() {
    return {
      title: '',
      confirm: false,
      chextShow: false,
      postStatus: false,
      navFrist: [
        {
          name: '基础信息',
          type: 1,
        },
        // {
        //   name: '注册证',
        //   type: 2,
        // },
        // {
        //   name: '备案凭证',
        //   type: 3,
        // },
        // {
        //   name: '经销信息',
        //   type: 4,
        // },
        // {
        //   name: '首营审批',
        //   type: 5,
        // },
      ],
      clickIndex: 1,
      status: 1, // 1是查看  2是审核  3是编辑
      reviewId: '',
      detailsInfo: {},
      userInfo: {},
      showPass: false,
      roleList: [],
    }
  },
  created() {
    this.status = this.$route.query.status
    this.reviewId = this.$route.query.reviewId
    this.roleList = JSON.parse(localStorage.getItem('userRoleList'))
    this.showPass = this.roleList.indexOf(1) != -1
    this.queryDetail()
    this.productReviewUser()
    this.$root.$on('changeType', val => {
      let obj = {}
      if (val == 1) {
        obj = {
          name: '注册证',
          type: 2,
        }
      } else {
        obj = {
          name: '备案凭证',
          type: 3,
        }
      }
      this.navFrist = [
        {
          name: '基础信息',
          type: 1,
        },
        obj,
        { name: '经销信息', type: 4 },
      ]
      if (val == 1) {
        this.clickIndex = 2
      } else {
        this.clickIndex = 3
      }
    })
  },
  methods: {
    // 产品弹框
    confirmClick() {
      this.chextShow = false
      this.confirm = true
      if (this.status == 2 && this.clickIndex == 5) {
        this.pass(1)
      } else if (this.status == 3 || (this.status == 2 && this.clickIndex != 5)) {
        this.editSave()
      } else if (this.status == 2 && this.clickIndex == 5 && this.showPass) {
        this.pass(2)
      }
    },
    // 提交
    async editSave(e, type) {
      let postFrom1 = this.$refs.productBaseInfo.postFrom
      let postFrom2 = {}
      let postFrom4 = {}
      if (!postFrom1.name) {
        this.$Message.warning('请选择/填写产品名称')
        return
      }
      if (!postFrom1.factory_id) {
        this.$Message.warning('请选择厂家')
        return
      }
      if (!postFrom1.name) {
        this.$Message.warning('请选择/填写产品名称')
        return
      }
      if (!postFrom1.storage_condition) {
        this.$Message.warning('请填写储运条件')
        return
      }
      // if (!postFrom1.unit) {
      //   this.$Message.warning('请填写最小包装规格')
      //   return
      // }
      postFrom1.unit = {
        qty: postFrom1.unit.qty ? postFrom1.unit.qty : '',
        unit: postFrom1.unit.unit ? postFrom1.unit.unit : '',
        dose_unit: postFrom1.unit.dose_unit ? postFrom1.unit.dose_unit : '',
      }
      postFrom1.packing_size = {
        qty: postFrom1.packing_size.qty ? postFrom1.packing_size.qty : '',
        unit: postFrom1.unit.unit ? postFrom1.unit.unit : '',
        dose_unit: postFrom1.packing_size.dose_unit ? postFrom1.packing_size.dose_unit : '',
      }
      // if (!postFrom1.packing_size) {
      //   this.$Message.warning('请填写包装规格')
      //   return
      // }
      if (!postFrom1.qualification_maintain_type) {
        this.$Message.warning('请选择资料维护')
        return
      }
      if (postFrom1.image_urls.length === 0 || postFrom1.image_files.length === 0 || !postFrom1.image_file_name.length === 0) {
        this.$Message.warning('请选上传图片资料')
        return
      }
      if (postFrom1.qualification_maintain_type == '1') {
        postFrom2 = this.$refs.registeCerticate.postFrom
        let arr = this.$refs.registeCerticate.listData
        arr.forEach((item, index) => {
          item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
        })
        if (!postFrom2.licence_file_key || !postFrom2.licence_file_name || !postFrom2.licence_file_url) {
          this.$Message.warning('请上传注册证')
          return
        }

        if (!postFrom2.licence_product_categories) {
          this.$Message.warning('请选择产品分类')
          return
        }
        if (!postFrom2.licence_code) {
          this.$Message.warning('请填写注册证编号')
          return
        }
        if (!postFrom2.production_address) {
          this.$Message.warning('请填写生产地址')
          return
        }
        if (!postFrom2.creator_company_name) {
          this.$Message.warning('请填写注册人名称')
          return
        }
        if (!postFrom2.creator_company_address) {
          this.$Message.warning('请填写注册人住所')
          return
        }
        if (!postFrom2.proxy_name) {
          this.$Message.warning('请填写代理人名称')
          return
        }
        if (!postFrom2.comprise_desc) {
          this.$Message.warning('请填写结构及组成')
          return
        }
        if (!postFrom2.application_scope) {
          this.$Message.warning('请填写适用范围')
          return
        }
        if (!postFrom2.registrant_time) {
          this.$Message.warning('请选择批准日期')
          return
        }
        if (!postFrom2.end_time) {
          this.$Message.warning('请选择有效期至')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请新增至少一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name || !arr[i].unit_price || (!arr[i].tax_rate && arr[i].tax_rate != 0)) {
            this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          } // 包装单位必填
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        if (!this.confirm) {
          //  let res =  await  this.$http.post(this.$api.productDuplication,{review_id: this.$route.query.reviewId,licence_product_code: arr},true)
          //   if(res.data) {
          //       this.chextShow = true
          //       this.title = res.data.join(',')
          //       return
          //     }
          let res = await this.$http.post(this.$api.productDuplication, { review_id: this.$route.query.reviewId, licence_product_code: arr }, true)
          if (res.data) {
            this.chextShow = true
            this.title = res.data.join(',')
            return
          }
        }
        postFrom2.licence_product_code = arr
      } else {
        postFrom2 = this.$refs.recordCertificateP.postFrom
        let arr = this.$refs.recordCertificateP.listData
        arr.forEach((item, index) => {
          item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
        })
        if (!postFrom2.record_file_name) {
          this.$Message.warning('请填写注册证')
          return
        }
        if (!postFrom2.record_product_categories) {
          this.$Message.warning('请选择产品分类')
          return
        }
        if (!postFrom2.record_code) {
          this.$Message.warning('请填写备案号')
          return
        }

        if (!postFrom2.record_name) {
          this.$Message.warning('请填写备案号名称')
          return
        }
        if (!postFrom2.record_time) {
          this.$Message.warning('请选择备案日期')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请至少添加一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name || !arr[i].unit_price || (!arr[i].tax_rate && arr[i].tax_rate != 0)) {
            this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          }
          // 包装单位必填
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        if (!this.confirm) {
          // this.$http.post(this.$api.productDuplication,{review_id: this.$route.query.reviewId,record_product_code: arr},true).then((res) => {
          //   if(res.data) {
          //     this.chextShow = true
          //     this.title = res.data.join(',')
          //     // return
          //   }
          // })
          let res = await this.$http.post(this.$api.productDuplication, { review_id: this.$route.query.reviewId, licence_product_code: arr }, true)
          if (res.data) {
            this.chextShow = true
            this.title = res.data.join(',')
            return
          }
        }
        postFrom2.record_product_code = arr
      }
      postFrom4 = this.$refs.dealerInfo.postFrom
      if (!postFrom4.payment_days) {
        this.$Message.warning('请填写账期')
        return
      }
      if (!postFrom4.deal_type) {
        this.$Message.warning('请填写交易类型')
        return
      }
      let lastPostFrom = Object.assign({}, postFrom1, postFrom2, postFrom4)
      lastPostFrom.image_files = lastPostFrom.image_files ? lastPostFrom.image_files.join(',') : ''
      lastPostFrom.image_file_name = lastPostFrom.image_file_name ? lastPostFrom.image_file_name.join(',') : ''
      lastPostFrom.review_id = this.reviewId
      this.lastPostObj = lastPostFrom
      if (!type) {
        this.postStatus = true
      } else {
        //  首营审批 点保存是保存前面页面得信息
        this.surePost(true, true)
        return true
      }
      sessionStorage.setItem('updataCache', '0')
    },
    surePost(flag, type) {
      // 分为了两块，一个licence_product_code一个record_product_code
      if (this.lastPostObj.licence_product_code != null) {
        if (this.lastPostObj.licence_product_code[0] != null) {
          for (let i = 0; i < this.lastPostObj.licence_product_code.length; i++) {
            if (!this.lastPostObj.licence_product_code[i].packing_size.dose_unit) {
              this.lastPostObj.licence_product_code[i].packing_size.dose_unit = ''
            }
            if (!this.lastPostObj.licence_product_code[i].packing_size.qty) {
              this.lastPostObj.licence_product_code[i].packing_size.qty = ''
            }
            if (!this.lastPostObj.licence_product_code[i].packing_size.unit) {
              this.lastPostObj.licence_product_code[i].packing_size.unit = ''
            }
            if (!this.lastPostObj.licence_product_code[i].unit.unit) {
              this.lastPostObj.licence_product_code[i].unit.unit = ''
            }
            if (!this.lastPostObj.licence_product_code[i].unit.dose_unit) {
              this.lastPostObj.licence_product_code[i].unit.dose_unit = ''
            }
            if (!this.lastPostObj.licence_product_code[i].unit.qty) {
              this.lastPostObj.licence_product_code[i].unit.qty = ''
            }
          }
        }
      }
      if (this.lastPostObj.record_product_code != null) {
        if (this.lastPostObj.record_product_code[0] != null) {
          for (let i = 0; i < this.lastPostObj.record_product_code.length; i++) {
            if (!this.lastPostObj.record_product_code[i].packing_size.dose_unit) {
              this.lastPostObj.record_product_code[i].packing_size.dose_unit = ''
            }
            if (!this.lastPostObj.record_product_code[i].packing_size.qty) {
              this.lastPostObj.record_product_code[i].packing_size.qty = ''
            }
            if (!this.lastPostObj.record_product_code[i].packing_size.unit) {
              this.lastPostObj.record_product_code[i].packing_size.unit = ''
            }
            if (!this.lastPostObj.record_product_code[i].unit.unit) {
              this.lastPostObj.record_product_code[i].unit.unit = ''
            }
            if (!this.lastPostObj.record_product_code[i].unit.dose_unit) {
              this.lastPostObj.record_product_code[i].unit.dose_unit = ''
            }
            if (!this.lastPostObj.record_product_code[i].unit.qty) {
              this.lastPostObj.record_product_code[i].unit.qty = ''
            }
          }
        }
      }
      this.$http.post(this.$api.productEdit, this.lastPostObj, true).then(res => {
        this.postStatus = false
        if (!type) {
          this.$Message.success('保存成功')
          this.$router.go(-1)
        }
      })
      sessionStorage.setItem('updataCache', '0')
    },
    back() {
      this.$router.go(-1)
    },
    productReviewUser() {
      this.$http.get(this.$api.productReviewUser).then(res => {
        this.userInfo = res.data
      })
    },
    async pass(num) {
      let arr = []
      let postFrom = this.$refs.approval.postFrom
      if (num == 2) {
        postFrom.purchase_review_status = '1'
        postFrom.quality_review_status = '1'
        postFrom.finance_review_status = '1'
        postFrom.manager_review_status = '1'
        postFrom.purchase_advice = '同意'
        postFrom.quality_advice = '同意'
        postFrom.finance_advice = '同意'
        postFrom.manager_advice = '同意'
        postFrom.purchase_sign_date = this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
        postFrom.quality_sign_date = this.$moment(new Date().getTime() + 10 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss')
        postFrom.finance_sign_date = this.$moment(new Date().getTime() + 20 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss')
        postFrom.manager_sign_date = this.$moment(new Date().getTime() + 30 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss')
        postFrom.purchase_sign = this.userInfo.purchase_sign
        postFrom.quality_sign = this.userInfo.quality_sign
        postFrom.finance_sign = this.userInfo.finance_sign
        postFrom.manager_sign = this.userInfo.manager_sign
      }
      if (this.detailsInfo.qualification_maintain_type == 1) {
        arr = this.$refs.registeCerticate.listData
        arr.forEach((item, index) => {
          item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
        })
        // for (let i = 0; i < arr.length; i++) {
        //   if (!arr[i].product_model_code) {
        //     this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
        //     return
        //   }
        // }
        if (!this.confirm) {
          // await  this.$http.post(this.$api.productDuplication,{review_id: this.$route.query.reviewId,licence_product_code: arr},true).then((res) => {
          //     if(res.data) {
          //       this.chextShow = true
          //       this.title = res.data.join(',')
          //       return
          //     }
          //   })
          let res = await this.$http.post(this.$api.productDuplication, { review_id: this.$route.query.reviewId, licence_product_code: arr }, true)
          if (res.data) {
            this.chextShow = true
            this.title = res.data.join(',')
            return
          }
        }
        postFrom.licence_product_code = arr
      } else {
        arr = this.$refs.recordCertificateP.listData
        arr.forEach((item, index) => {
          item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
        })
        // for (let i = 0; i < arr.length; i++) {
        //   if (!arr[i].product_model_code) {
        //     this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
        //     return
        //   }
        // }
        postFrom.record_product_code = arr
      }
      postFrom.business_review_id = this.detailsInfo.business_review_id

      if (this.returnDisabled([1, 3, 4])) {
        if (!postFrom.purchase_advice) {
          this.$Message.warning('请填写采购部审核意见')
          return
        }
        if (!postFrom.purchase_sign) {
          this.$Message.warning('请填写采购部负责人签字')
          return
        }
        if (!postFrom.purchase_review_status) {
          this.$Message.warning('请选择采购部审核意见')
          return
        }
        if (!postFrom.purchase_sign_date) {
          this.$Message.warning('请选择采购部签字日期')
          return
        }
      }
      if (this.returnDisabled([1, 8])) {
        if (!postFrom.quality_advice) {
          this.$Message.warning('请填写质量管理部门审核意见')
          return
        }
        if (!postFrom.quality_sign) {
          this.$Message.warning('请填写质量管理部门负责人签字')
          return
        }
        if (!postFrom.quality_review_status) {
          this.$Message.warning('请选择质量管理部门审核意见')
          return
        }
        if (!postFrom.quality_sign_date) {
          this.$Message.warning('请选择质量管理部门签字日期')
          return
        }
      }
      if (this.returnDisabled([1, 10, 11])) {
        if (!postFrom.finance_advice) {
          this.$Message.warning('请填写财务部审核意见')
          return
        }
        if (!postFrom.finance_sign) {
          this.$Message.warning('请填写财务部负责人签字')
          return
        }
        if (!postFrom.finance_review_status) {
          this.$Message.warning('请选择财务部审核意见')
          return
        }
        if (!postFrom.finance_sign_date) {
          this.$Message.warning('请选择财务部签字日期')
          return
        }
      }
      if (this.returnDisabled([1])) {
        if (!postFrom.manager_advice) {
          this.$Message.warning('请填写总经理审核意见')
          return
        }
        if (!postFrom.manager_sign) {
          this.$Message.warning('请填写总经理签字')
          return
        }
        if (!postFrom.manager_review_status) {
          this.$Message.warning('请选择总经理审核意见')
          return
        }
        if (!postFrom.manager_sign_date) {
          this.$Message.warning('请选择总经理或管理者代表签字日期')
          return
        }
      }
      if (num == 1) {
        let res = await this.editSave(true, true)
        if (res) {
          this.$http.post(this.$api.productProductReview, postFrom, true).then(res => {
            this.$Message.success('审核完成')
            this.$router.go(-1)
          })
        }
      } else {
        let postFrom1 = this.$refs.productBaseInfo.postFrom
        let postFrom2 = {}
        let postFrom4 = {}
        if (!postFrom1.name) {
          this.$Message.warning('请选择/填写产品名称')
          return
        }
        if (!postFrom1.factory_id) {
          this.$Message.warning('请选择厂家')
          return
        }
        if (!postFrom1.name) {
          this.$Message.warning('请选择/填写产品名称')
          return
        }
        if (!postFrom1.storage_condition) {
          this.$Message.warning('请填写储运条件')
          return
        }
        // if (!postFrom1.unit) {
        //   this.$Message.warning('请填写最小包装规格')
        //   return
        // }
        postFrom1.unit = {
          qty: postFrom1.unit.qty ? postFrom1.unit.qty : '',
          unit: postFrom1.unit.unit ? postFrom1.unit.unit : '',
          dose_unit: postFrom1.unit.dose_unit ? postFrom1.unit.dose_unit : '',
        }
        postFrom1.packing_size = {
          qty: postFrom1.packing_size.qty ? postFrom1.packing_size.qty : '',
          unit: postFrom1.unit.unit ? postFrom1.unit.unit : '',
          dose_unit: postFrom1.packing_size.dose_unit ? postFrom1.packing_size.dose_unit : '',
        }
        // if (!postFrom1.packing_size) {
        //   this.$Message.warning('请填写包装规格')
        //   return
        // }
        if (!postFrom1.qualification_maintain_type) {
          this.$Message.warning('请选择资料维护')
          return
        }
        if (postFrom1.image_urls.length === 0 || postFrom1.image_files.length === 0 || !postFrom1.image_file_name.length === 0) {
          this.$Message.warning('请选上传图片资料')
          return
        }
        console.log(postFrom1.qualification_maintain_type, 'postFrom1.qualification_maintain_type')
        if (postFrom1.qualification_maintain_type == '1') {
          postFrom2 = this.$refs.registeCerticate.postFrom
          let arr = this.$refs.registeCerticate.listData
          arr.forEach((item, index) => {
            item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
          })
          if (!postFrom2.licence_file_key || !postFrom2.licence_file_name || !postFrom2.licence_file_url) {
            this.$Message.warning('请上传注册证')
          }
          if (!postFrom2.licence_product_categories) {
            this.$Message.warning('请选择产品分类')
            return
          }
          if (!postFrom2.licence_code) {
            this.$Message.warning('请填写注册证编号')
          }
          if (!postFrom2.production_address) {
            this.$Message.warning('请填写生产地址')
          }
          if (!postFrom2.creator_company_name) {
            this.$Message.warning('请填写注册人名称')
          }
          if (!postFrom2.creator_company_address) {
            this.$Message.warning('请填写注册人住所')
          }
          if (!postFrom2.proxy_name) {
            this.$Message.warning('请填写代理人名称')
          }
          if (!postFrom2.comprise_desc) {
            this.$Message.warning('请填写结构及组成')
          }
          if (!postFrom2.application_scope) {
            this.$Message.warning('请填写适用范围')
          }
          if (!postFrom2.registrant_time) {
            this.$Message.warning('请选择批准日期')
          }
          if (!postFrom2.end_time) {
            this.$Message.warning('请选择有效期至')
          }
          if (arr.length == 0) {
            this.$Message.warning('请新增至少一组规格型号')
          }

          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].model_name || !arr[i].unit_price || (!arr[i].tax_rate && arr[i].tax_rate != 0)) {
              this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
              return
            }
            if (arr[i].tax_rate * 1 > 100) {
              this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
              return
            }
            // 包装单位必填
            if (!arr[i].unit.unit) {
              this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
              return
            }
          }
          if (!this.confirm) {
            // this.$http.post(this.$api.productDuplication,{review_id: this.$route.query.reviewId,licence_product_code: arr},true).then((res) => {
            //   if(res.data) {
            //     this.chextShow = true
            //     this.title = res.data.join(',')

            //     return
            //   }
            // })
            let res = await this.$http.post(this.$api.productDuplication, { review_id: this.$route.query.reviewId, licence_product_code: arr }, true)
            if (res.data) {
              this.chextShow = true
              this.title = res.data.join(',')
              return
            }
          }
          postFrom2.licence_product_code = arr
        } else {
          postFrom2 = this.$refs.recordCertificateP.postFrom
          let arr = this.$refs.recordCertificateP.listData
          arr.forEach((item, index) => {
            item.notax_unit_price = (item.unit_price / (1 + item.tax_rate / 100)).toFixed(4)
          })
          if (!postFrom2.record_file_name) {
            this.$Message.warning('请填写注册证')
            return
          }
          if (!postFrom2.record_product_categories) {
            this.$Message.warning('请选择产品分类')
            return
          }
          if (!postFrom2.record_code) {
            this.$Message.warning('请填写备案号')
            return
          }
          if (!postFrom2.record_name) {
            this.$Message.warning('请填写备案号名称')
            return
          }
          if (!postFrom2.record_time) {
            this.$Message.warning('请选择备案日期')
            return
          }
          if (arr.length == 0) {
            this.$Message.warning('请至少添加一组规格型号')
            return
          }
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].model_name || !arr[i].unit_price || (!arr[i].tax_rate && arr[i].tax_rate != 0)) {
              this.$Message.warning(`请填写第${i + 1}组规格型号数据再提交`)
              return
            }
            if (arr[i].tax_rate * 1 > 100) {
              this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
              return
            }
            // 包装单位必填
            if (!arr[i].unit.unit) {
              this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
              return
            }
          }
          if (!this.confirm) {
            // this.$http.post(this.$api.productDuplication,{review_id: this.$route.query.reviewId,record_product_code: arr},true).then((res) => {
            //   if(res.data) {
            //     this.chextShow = true
            //     this.title = res.data.join(',')
            //     return
            //   }
            // })
            let res = await this.$http.post(this.$api.productDuplication, { review_id: this.$route.query.reviewId, licence_product_code: arr }, true)
            if (res.data) {
              this.chextShow = true
              this.title = res.data.join(',')
              return
            }
          }
          postFrom2.record_product_code = arr
        }
        postFrom4 = this.$refs.dealerInfo.postFrom
        if (!postFrom4.payment_days) {
          this.$Message.warning('请填写账期')
        }
        if (!postFrom4.deal_type) {
          this.$Message.warning('请填写交易类型')
        }

        let lastPostFrom = Object.assign({}, postFrom1, postFrom2, postFrom4)
        lastPostFrom.image_files = lastPostFrom.image_files ? lastPostFrom.image_files.join(',') : ''
        lastPostFrom.image_file_name = lastPostFrom.image_file_name ? lastPostFrom.image_file_name.join(',') : ''
        lastPostFrom.review_id = this.reviewId
        this.lastPostObj = lastPostFrom

        this.$http.post(this.$api.productEdit, this.lastPostObj, true).then(res => {
          this.$http.post(this.$api.productProductReviewAll, postFrom, true).then(() => {
            this.$Message.success('审核完成')
            this.$router.go(-1)
          })
        })
      }
      sessionStorage.setItem('updataCache', '0')
    },
    // 查看详情
    queryDetail() {
      this.$http.get(this.$api.productProductDetail, { review_id: this.reviewId }, true).then(res => {
        this.detailsInfo = res.data
        if (this.detailsInfo.near_update_field) {
          this.detailsInfo.changeList = this.detailsInfo.near_update_field.split(',')
        }
        this.$root.$emit('productInfo', this.detailsInfo)
        let obj = {}
        if (this.detailsInfo.qualification_maintain_type == 1) {
          obj = {
            name: '注册证',
            type: 2,
          }
        } else {
          obj = {
            name: '备案凭证',
            type: 3,
          }
        }
        if (this.status != 3) {
          this.navFrist = [...this.navFrist, obj, { name: '经销信息', type: 4 }, { name: '首营审批', type: 5 }]
        } else {
          this.navFrist = [...this.navFrist, obj, { name: '经销信息', type: 4 }]
        }
      })
    },
    clickNav(type) {
      this.clickIndex = type
      this.$root.$emit('productInfo', this.detailsInfo)
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    overflow-y: auto;
    .formDiv {
      flex: 1;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
    }
  }

  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.topModal {
  padding-bottom: 20px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    .white_top {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      p:nth-child(1) {
        display: block;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        margin-bottom: 0px;
      }
    }
    .white {
      width: 100%;
      height: 150px;
      overflow-y: auto;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
.foot {
  text-align: right;
}
</style>
